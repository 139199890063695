html, body, #root {
  height: 100%;
  margin: 0px auto;

  --foreground: white;
  --background: black;
  --hover: #555555;
  --shadow: #888888;
  --link: #91D2FA;
}
body {
  background-color: var(--background);
  color: var(--foreground);
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  /* prevent scrolling on mobile */
  body {
    touch-action: none;
  }
}
